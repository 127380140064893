import { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { makeStyles } from '@mui/styles';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import {
  Box,
  Typography,
  Card,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Pagination,
  PaginationItem,
  Table,
} from '@mui/material';

import Loader from '../Dashboard/BackdropLoader';
import AxiosInstance, { GOOGLE_CLOUD_BASE_URL } from '../../api/axios';
import * as utils from '../../utils/index';
import CommentText from './CommentText';
import TableHead from './TableHead';

const columns = [
  { name: 'company.name', label: 'Company', align: 'left', sortable: true },
  { name: 'latest_score', label: 'Score', sortable: true },
  { name: 'change_yoy', label: 'Change', sortable: true },
  { name: 'date_change', label: 'Date', type: 'date', sortable: true },
  { name: 'comment', label: 'Comment', sortable: true },
];

const useStyles = makeStyles(() => ({
  wrapper: {
    margin: '40px',
    '@media (max-width: 769px)': {
      margin: '20px',
    },
  },
  wrapperPaper: {
    boxShadow: 'none',
  },
  reportLink: {
    color: 'inherit',
    cursor: 'pointer',
    textDecoration: 'underline',
    textDecorationStyle: 'solid',
  },
  showMoreLink: {
    color: 'inherit',
    cursor: 'pointer',
    textDecoration: 'underline',
    textDecorationStyle: 'solid',
    marginLeft: '8px',
  },
  commentColumn: {
    '& *': {
      fontSize: '14px',
    },
    width: '75%',
    paddingLeft: '20px !important',
    '@media (max-width: 1024px)': {
      display: 'none',
    },
  },
  pagination: {
    '& ul': {
      margin: '0',
      justifyContent: 'center',
    },
    '& ul li': {
      margin: '0',
    },
    '@media (max-width: 600px)': {
      '& ul li button': {
        margin: '0 2px',
      },
    },
  },
  table: {
    '& thead th': {
      fontWeight: '600',
    },
    '& tbody td': {
      fontWeight: '300',
    },
    '& tbody tr:hover': {
      cursor: 'pointer',
    },
  },
  tableWrapper: {
    marginTop: '40px',
    overflowX: 'auto',
    border: '1px solid rgb(224, 224, 224)',
    borderRadius: '5px',
    '@media (max-width: 769px)': {
      marginTop: '20px',
    },
    '& table': {
      marginTop: '0',
    },
    '& thead > tr > th:first-of-type span': {
      paddingLeft: '0 !important',
    },
    '& th': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.87)',
    },
    '& tbody tr:last-of-type td': {
      borderBottom: 'none',
    },
    '& td, & th': {
      padding: '14px !important',
      '@media (max-width: 1024px)': {
        padding: '14px 12px !important',
      },
    },
    '& td:nth-of-type(1), & th:nth-of-type(1)': {
      '@media (max-width: 1024px)': {
        paddingRight: '4px !important',
      },
    },
    '& td:nth-of-type(2), & th:nth-of-type(2), & td:nth-of-type(3), & th:nth-of-type(3)': {
      '@media (max-width: 1024px)': {
        padding: '14px 4px !important',
      },
    },
    '& td:nth-of-type(4), & th:nth-of-type(4)': {
      '@media (max-width: 1024px)': {
        paddingLeft: '4px !important',
      },
    },
    '@media (max-width: 1024px)': {
      '& th:nth-of-type(5)': {
        display: 'none',
      },
    },
  },
  noRecordsMessage: {
    padding: '20px',
    textAlign: 'center',
  },
}));

const ResearchContent = () => {
  const [filterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [reports, setReports] = useState([]);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [pagesCount, setPagesCount] = useState(1);
  const [order, setOrder] = useState(null);
  const [orderBy, setOrderBy] = useState(null);

  const limit = 25;

  useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' });
  }, [reports]);

  const classes = useStyles();

  useEffect(() => {
    const loadData = async () => {
      setOpen(true);

      try {
        const params = { page, limit, orderBy, order };
        const searchParams = new URLSearchParams(_.pickBy(params, (value) => Boolean(value)));
        const { data } = await AxiosInstance.get(`reports?${searchParams.toString()}`);

        setReports(data.data);
        setPagesCount(Math.ceil(data.meta.itemCount / data.meta.limit));
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error:', error);
      } finally {
        setOpen(false);
      }
    };

    loadData();
  }, [page, order, orderBy]);

  useEffect(() => {
    setPage(1);
  }, [order, orderBy]);

  const handleReportLink = (reportUrl) => {
    if (!reportUrl) {
      return;
    }

    const targetObject = _.find(reports, { url: reportUrl });

    if (!targetObject) {
      return;
    }

    const body = {
      fileUrl: targetObject.url,
      fileDate: targetObject.date_change,
      reportName: targetObject.reportName,
    };
    const url = `${GOOGLE_CLOUD_BASE_URL}company/watermark/${body.reportName}?token=${utils.getAccessToken()}&fileUrl=${
      body.fileUrl
    }`;

    const newWindow = window.open(url, '_blank');
    if (newWindow == null || typeof newWindow === 'undefined') {
      document.location.href = url;
    } else {
      newWindow.focus();
    }
  };

  return (
    <>
      <Loader setOpen={setOpen} open={open} />
      <Box className={classes.wrapper}>
        <Paper className={classes.wrapperPaper}>
          <Card className="cardClass" style={{ margin: '0' }}>
            <Typography variant="h4" className="cardTitle">
              Latest Research
            </Typography>
          </Card>

          {reports && reports.length ? (
            <Box className={classes.tableWrapper}>
              <Table className={classes.table}>
                <TableHead
                  columns={columns}
                  order={order}
                  orderBy={orderBy}
                  setOrder={setOrder}
                  setOrderBy={setOrderBy}
                />
                <TableBody>
                  {reports.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell align="left">{item.company.name}</TableCell>
                      <TableCell align="center">{item.latest_score}</TableCell>
                      <TableCell align="center">{item.change_yoy}</TableCell>
                      <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                        <span
                          className={classes.reportLink}
                          role="presentation"
                          onClick={() => {
                            handleReportLink(item.url);
                          }}
                        >
                          {moment(item.date_change).format('YYYY-MM-DD')}
                        </span>
                      </TableCell>
                      <TableCell align="left" className={classes.commentColumn}>
                        <CommentText text={item.comment} />
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Pagination
                        className={classes.pagination}
                        siblingCount={1}
                        boundaryCount={1}
                        count={pagesCount}
                        page={page}
                        renderItem={(item) => {
                          return (
                            <PaginationItem
                              style={{ justifyContent: 'center' }}
                              slots={{ previous: KeyboardArrowLeft, next: KeyboardArrowRight }}
                              {...item}
                            />
                          );
                        }}
                        onChange={(event, value) => {
                          setPage(value);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          ) : (
            !open && <Typography className={classes.noRecordsMessage}>No Data Found</Typography>
          )}
        </Paper>
      </Box>
    </>
  );
};

export default ResearchContent;
